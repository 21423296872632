import React, { useEffect, useState} from 'react'
import {
	Card,
	Image,
} from 'semantic-ui-react'
import {Link} from 'gatsby'
import * as styles from '../BlogList/styles/blogList.module.css'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth
} from '../../globalVar'



const mapProductsToItems = (products, space, cameFrom, page, type, offset, listName) => {
	return products.map(({name, id, mainImageHref, investment, url, deployment, summary }, index) => {
		let productName ="";
		let strings = name.split(" ");
		let length = 0;
		let line = 1;
		for(let i=0;i<strings.length;i++) {
			if(line===2 && (length + strings[i].length +.5 > space-3) ) {

				const lastIndex = productName.lastIndexOf(" ");
				productName = productName.substr(0, lastIndex);
				productName = productName.concat("...");
				break;
			}else if( (length + strings[i].length +.5 > space) ) {
				line++;
				length = strings[i].length+.5
				productName = productName.concat(`${strings[i]} `);

			}else {
				length+=strings[i].length+.5;
				productName = productName.concat(`${strings[i]} `);
			}
		}

		return (
			<Card
				as={Link}
				to={`/franchise/${url}/`}
				state={{
					cameFrom,
					page,
					index:index+1
				}}
				key={type==="product" ? id:url}
			>
				<Image className={styles.franchiseLogoList} fluid alt={name} src={mainImageHref} />
				<Card.Content className={styles.cardBGGrey}>
					<Card.Header>{productName}</Card.Header>
					<Card.Description>
						<div>{summary}</div>
					</Card.Description>
				</Card.Content>
				<Card.Content extra>
					<Card.Description><br/>&nbsp;</Card.Description>
				</Card.Content>
				<Card.Content className={styles.cardBGGrey+" "+styles.cardExtraNoBorder}>
					<Card.Description>Starts at<br/>{investment.replace("Starts at","")}</Card.Description>
				</Card.Content>
			</Card>
		)
	})
}
export default ({products, shown, grid, cameFrom, page, offset, listName, type="product"}) => {
	const [windowWidth, setWindowWidth] = useState(0)


	useEffect(()=> {
		setWindowWidth(window.innerWidth)
	}, [])

	useEffect(()=>{
		if(type==="product") {
			let positionOffset = 0
			if(page && offset) {
				positionOffset= (page-1)*offset
			}
			const dataLayerList = products.map(({name, sku, meta}, index)=>{
				return {
					'name': name,
					'id': sku,
					'list': listName,
					'position':positionOffset+index+1,

					'item_id': sku,
					'item_name': name,
					'index': positionOffset+index+1,
					'item_list_id': listName,
					'item_list_name': listName,
				}
			})
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				'event': 'productList',
				'ecommerce': {
					'impressions': dataLayerList
				}
			});
		}
		/*Sept. 16 2020
		 *[product[0].url] works because when getting new products, the productList gets unmounted and a Loader is replaced with it
		 *though this assumes that the product url will always be unique and that the product list will get unmounted while getting new products
		 */
	}, [products[0].url])


	let containerWidth = 0;
	let fontSize = 0;

	/*windowWidth if values are from https://semantic-ui.com/elements/container.html*/
	if(windowWidth > 1200 ) {
		fontSize=11.5
		containerWidth = 1127
	}else if( windowWidth <= 1200 && windowWidth >= 992 ) {
		fontSize=11
		containerWidth = 933
	}else if( windowWidth <= 991 && windowWidth >= 768 ) {
		fontSize=10.5
		containerWidth = 723
	}else if( windowWidth < 768 ) {
		fontSize=9.5
		containerWidth = windowWidth-28
	}
	let space = (containerWidth* grid / 16 /shown ) -15 /*-15 for margin*/
	space = space / fontSize

	return (
		<Card.Group
			itemsPerRow={shown}
		>
			{mapProductsToItems(products, space, cameFrom, page, type, offset, listName)}
		</Card.Group>
	)
}
